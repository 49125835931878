<template>
  <b-container fluid class="main bg-white">

    <ValidationObserver ref="searchObserver" v-slot="{ }">
    <b-form @submit.prevent="localApplyFilter">
      <div class="d-flex flex-wrap border-bottom stickySearch">
        <h2 v-t="'wyroby.title'" class="flex-grow-1"/>
        <div >
          <b-button-group class="mr-2">
            <b-button variant="primary" type="submit" size="sm" v-t="'button.search.label'"></b-button>
            <b-button variant="outline-secondary" size="sm" @click="resetFilterAndCatalog" v-t="'button.clear.label'"></b-button>
          </b-button-group>
          <b-button class="mr-2"
            size="sm"
            variant="outline-secondary"
            :aria-expanded="showFilter ? 'true' : 'false'"
            aria-controls="collapse-filter"
            @click="showFilter = !showFilter">
            <span v-if="showFilter"><font-awesome-icon icon="eye-slash" fixed-width/></span>
            <span v-else><font-awesome-icon icon="eye" fixed-width/></span>
          </b-button>
          <b-dropdown size="sm" variant="outline-secondary" no-caret right>
            <template v-slot:button-content>
              <font-awesome-icon icon="bars" fixed-width/>
            </template>
            <b-dropdown-group id="dropdown-group-1" v-bind:header="$t('button.export.label')">
             <b-dropdown-item
                    target="_blank"
                    :disabled="!selectedIds || selectedIds.length < 1"
                    @click="onExport(axios.defaults.baseURL + 'data/itemSet/export')">{{$t('button.pdf.label')}}</b-dropdown-item>
            <b-dropdown-item
                    target="_blank"
                    :disabled="!selectedIds || selectedIds.length < 1"
                    @click="onExportGoogle(axios.defaults.baseURL + 'data/itemSet/export-sheets')">{{$t('button.sheets.label')}}</b-dropdown-item>
            </b-dropdown-group>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-group id="dropdown-group-1" v-bind:header="$t('button.offer.label')">
              <b-dropdown-item
                    target="_blank"
                    :disabled="!selectedIds || selectedIds.length < 1"
                    @click="onExport(axios.defaults.baseURL + 'data/itemSet/offer')">{{$t('button.pdf.label')}}</b-dropdown-item>
              <b-dropdown-item
                    target="_blank"
                    :disabled="!selectedIds || selectedIds.length < 1"
                    @click="onExportGoogle(axios.defaults.baseURL + 'data/itemSet/offer-docs')">{{$t('button.docs.label')}}</b-dropdown-item>
              </b-dropdown-group>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-group id="dropdown-group-1" v-bind:header="$t('button.offerPrice.label')">
              <b-dropdown-item
                    target="_blank"
                    :disabled="!selectedIds || selectedIds.length < 1"
                    @click="onExport(axios.defaults.baseURL + 'data/itemSet/offer?price=1')">{{$t('button.pdf.label')}}</b-dropdown-item>
              <b-dropdown-item
                      target="_blank"
                      :disabled="!selectedIds || selectedIds.length < 1"
                      @click="onExportGoogle(axios.defaults.baseURL + 'data/itemSet/offer-docs?price=1')">{{$t('button.docs.label')}}</b-dropdown-item>
            </b-dropdown-group>
            <!-- <b-dropdown-item
                    target="_blank"
                    :disabled="!selectedIds || selectedIds.length < 1"
                    @click="onExport(axios.defaults.baseURL + 'data/itemSet/export')">{{$t('button.exportPDF.label')}}</b-dropdown-item>
            <b-dropdown-item
                    target="_blank"
                    :disabled="!selectedIds || selectedIds.length < 1"
                    @click="onExportGoogle(axios.defaults.baseURL + 'data/itemSet/export-sheets')">{{$t('button.exportSheets.label')}}</b-dropdown-item>
            <b-dropdown-item
                    target="_blank"
                    :disabled="!selectedIds || selectedIds.length < 1"
                    @click="onExport(axios.defaults.baseURL + 'data/itemSet/offer')">{{$t('button.offer.label')}}</b-dropdown-item>
            <b-dropdown-item
                    target="_blank"
                    :disabled="!selectedIds || selectedIds.length < 1"
                    @click="onExportGoogle(axios.defaults.baseURL + 'data/itemSet/offer-docs')">{{$t('button.offerDocs.label')}}</b-dropdown-item>
            <b-dropdown-item
                    target="_blank"
                    :disabled="!selectedIds || selectedIds.length < 1"
                    @click="onExport(axios.defaults.baseURL + 'data/itemSet/offer?price=1')">{{$t('button.offerPrice.label')}}</b-dropdown-item>
            <b-dropdown-item
                    target="_blank"
                    :disabled="!selectedIds || selectedIds.length < 1"
                    @click="onExportGoogle(axios.defaults.baseURL + 'data/itemSet/offer-docs?price=1')">{{$t('button.offerPriceDocs.label')}}</b-dropdown-item> -->
            </b-dropdown>
        </div>
      </div>

      <api-error :error="error"></api-error>

      <b-collapse id="collapse-filter" v-model="showFilter">
        <b-form-row class="mt-2">
          <b-form-group :label="$t('fields.catalog')" for="katalog" class="col-md-6 col-xl-3">
            <b-input-group><div class="flex-grow-1"><catalog-group-select
              id="katalog"
              v-model="filter.itemCatalogGroupId.value"
              :placeholder="$t('fields.catalog')"
              :allow-empty="false"
              :mainGroupOnly="true"
              v-on:input="changeCatalogGroup">
            </catalog-group-select></div>
            <b-input-group-append><b-button size="sm" variant="outline-secondary"
                      @click="saveDefaultCatalog(filter.itemCatalogGroupId.value)"
                      :disabled="!filter.itemCatalogGroupId.value"
                      :title="$t('button.set-default-catalog.title')">
                  <font-awesome-icon icon="save" fixed-width/>
                </b-button></b-input-group-append></b-input-group>
          </b-form-group>
          <b-form-group :label="$t('fields.itemCode')" for="itemCode" class="col-md-6 col-xl-3">
            <b-form-input id="itemCode"
                v-model="filter.itemCode.value.equals">
            </b-form-input>
          </b-form-group>
          <b-form-group :label="$t('fields.itemDesc')" for="itemDesc" class="col-md-6 col-xl-3">
            <b-form-input id="itemDesc"
                v-model="filter.itemDesc.value.equals">
            </b-form-input>
          </b-form-group>
          <b-form-group :label="$t('fields.nierotKategoria')" for="nierotKategoria" class="col-md-6 col-xl-3" v-if="$auth.hasAnyRole(['ROLE_SEE_NIEROT'])">
            <multiselect
              id="nierotKategoria"
              v-model="filter.nierotKategoria.value"
              label="value"
              track-by="id"
              :loading="loadingNierot"
              :options="nierotList"
              :multiple="true"
              :clear-on-select="false"
              :preserve-search="true"
              @open="loadNierot">
            </multiselect>
          </b-form-group>
        </b-form-row>

        <b-form-row>
            <!-- eslint-disable vue/no-use-v-if-with-v-for -->
            <search-filter v-for="(value, name) in filter" :key="name" v-if="value.custom"
              class="col-lg-4"
              :elementName="value.label"
              :catalogGroupId="filter.itemCatalogGroupId.value ? filter.itemCatalogGroupId.value.itemcataloggroupid: 0"
              v-model="filter[name].value"
              :allowedValues="getAllowedValues(value.label)"
              />
            <!-- eslint-enable vue/no-use-v-if-with-v-for -->
        </b-form-row>

        <div class="d-flex flex-wrap border-top pt-1 mt-3">
          <h4 class="flex-grow-1">{{$t('wyroby.set-elements')}}</h4>
          <div>
              <b-button variant="success" size="sm" @click="addSearchItem(null)" class="mr-2" v-t="'button.add.label'"></b-button>
              <b-button-group>
                <b-button size="sm"
                    :title="showFilterElem ? $t('button.hide.label') : $t('button.show.label')"
                    :class="showFilterElem ? null : 'collapsed'"
                    :variant="showFilterElem ? null : 'outline-secondary'"
                    :aria-expanded="showFilterElem ? 'true' : 'false'"
                    aria-controls="elem-collapse"
                    @click="showFilterElem = !showFilterElem">
                  <span v-if="showFilterElem"><font-awesome-icon icon="eye-slash" fixed-width/></span>
                  <span v-else><font-awesome-icon icon="eye" fixed-width/></span>
                </b-button>
                <b-button size="sm" v-if="showFilterElem" variant="outline-secondary"
                      @click="toogleElemFilter(false)"
                      :title="$t('button.show-active.title')">
                  <font-awesome-icon icon="window-minimize" fixed-width/>
                </b-button>
                <b-button size="sm" v-if="showFilterElem" variant="outline-secondary"
                      @click="toogleElemFilter(true)"
                      :title="$t('button.show-all.title')">
                  <font-awesome-icon icon="window-maximize" fixed-width/>
                </b-button>
              </b-button-group>
          </div>
        </div>

        <b-collapse v-model="showFilterElem" id="elem-collapse">
        <div class="card-columns">

          <div class="card bg-light" v-for="(elem,idx) in filter.items" :key="idx">
            <div class="card-header d-flex pb-1 pt-1 pr-2">
                <b-form-checkbox switch size="sm" :disabled="elem.editing == false" v-model="filter.items[idx].dataNotEquals.value.equals">{{$t('button.switch.not-contains.label')}}</b-form-checkbox>
                <b-link title="Minimalizuj" class="text-dark ml-auto mr-1" v-if="elem.editing" @click="elem.editing=!elem.editing">
                  <font-awesome-icon icon="window-minimize" fixed-width/>
                </b-link>
                <b-link :title="$t('button.update.title')" class="text-dark ml-auto mr-1" v-else @click="elem.editing=!elem.editing">
                  <font-awesome-icon icon="edit" fixed-width/>
                </b-link>
                <b-link :title="$t('button.delete.title')" class="text-dark" @click="delSearchItem(idx)">
                  <font-awesome-icon icon="times" fixed-width/>
                </b-link>
            </div>
            <div class="card-body pb-0 pt-1">
              <transition name="fade">
              <div v-if="elem.editing">
                <b-form-group :label="$t('fields.itemCode')" for="'itemCode_'+idx">
                  <b-form-input :id="'itemCode_'+idx"
                      autocomplete="item-code"
                      type="text"
                      v-model="filter.items[idx].itemCode.value.equals">
                  </b-form-input>
                </b-form-group>
                <b-form-group :label="$t('fields.group')" :for="'grupa_'+idx">
                  <multiselect :id="'grupa_'+idx"
                    v-model="filter.items[idx].itemCatalogGroupId.value"
                    :loading="loadingCatalogSubGroups"
                    :options="catalogSubGroups"
                    :multiple="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :allow-empty="true"
                    :max-height="150"
                    label="description"
                    track-by="itemcataloggroupid"
                    :placeholder="$t('fields.group')"
                    @open="loadCatalogSubGroups(filter.itemCatalogGroupId.value ? filter.itemCatalogGroupId.value.itemcataloggroupid: 0)"
                    v-on:input="(selectedOption, id) => getItemElements(selectedOption, id, idx)">
                  </multiselect>
                </b-form-group>
                <b-form-group :label="$t('fields.quantity')" for="'quantity_'+idx">
                  <b-input-group>
                  <b-form-input :id="'quantity_'+idx"
                      autocomplete="item-quantity1"
                      type="number"
                      v-model="filter.items[idx].quantity.value.greaterOrEqualThan">
                  </b-form-input>
                  <b-input-group-prepend is-text><font-awesome-icon icon="minus"/></b-input-group-prepend>
                  <b-form-input
                      autocomplete="item-quantity2"
                      type="number"
                      v-model="filter.items[idx].quantity.value.lessOrEqualThan">
                  </b-form-input>
                  </b-input-group>
                </b-form-group>

                <!-- eslint-disable vue/no-use-v-if-with-v-for -->
                <b-form-row>
                <search-filter v-for="(value, name) in filter.items[idx]" :key="name" v-if="value.custom"
                  class="col-lg-4"
                  :elementName="value.label"
                  :catalogGroupId="filter.items[idx].itemCatalogGroupId.value ? filter.items[idx].itemCatalogGroupId.value.itemcataloggroupid: 0"
                  v-model="filter.items[idx][name].value"
                  />
                </b-form-row>
                <!-- eslint-enable vue/no-use-v-if-with-v-for -->
              </div>
              <div v-else>
                <ul>
                  <li v-if="elem.itemCode.value.equals">{{$t('fields.itemCode')}}: <strong>{{elem.itemCode.value.equals}}</strong></li>
                  <li v-if="elem.itemCatalogGroupId.value">Grupa: <strong>{{elem.itemCatalogGroupId.value.description}}</strong></li>
                  <li v-if="elem.quantity.value.lessOrEqualThan || elem.quantity.value.greaterOrEqualThan">
                    {{$t('fields.quantity')}}: <strong>{{elem.quantity.value.greaterOrEqualThan?elem.quantity.value.greaterOrEqualThan:1}}-{{elem.quantity.value.lessOrEqualThan}}</strong>
                  </li>
                  <!-- eslint-disable vue/no-use-v-if-with-v-for -->
                  <li search-filter v-for="(value, name) in filter.items[idx]" :key="name" v-if="value.custom && value.value">
                    {{value.label}}: <strong>{{value.value.map(x => x['elementvalue']).join(', ')}}</strong>
                  </li>
                  <!-- eslint-enable vue/no-use-v-if-with-v-for -->
                </ul>
              </div>
              </transition>
            </div>
          </div>

        </div>
        </b-collapse>
        <hr class="mt-0"/>
      </b-collapse>
    </b-form>
    </ValidationObserver>

    <b-table ref="table" responsive="md" :small="true"
          selectable
          select-mode="multi"
          :items="localDataProvider"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filterStr"
          sticky-header="500px"
          @row-selected="onRowSelected"
          :busy="isBusy"
          api-url="data/itemSet">

          <template v-slot:cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <font-awesome-icon :icon="['far', 'check-square']" fixed-width/>
            </template>
            <template v-else>
              <font-awesome-icon :icon="['far', 'square']" fixed-width/>
            </template>
          </template>

          <template v-slot:head(selected)="{ selectAllRows, clearSelected }">
            <b-link :title="$t('button.selectAll.title')" class="text-dark" v-if="selectToggle" @click="() => { selectAllRows(); selectToggle=!selectToggle }">
              <font-awesome-icon :icon="['far', 'square']" fixed-width/>
            </b-link>
            <b-link :title="$t('button.deselectAll.title')" class="text-dark" v-if="!selectToggle" @click="() => { clearSelected(); selectToggle=!selectToggle }">
              <font-awesome-icon :icon="['far', 'check-square']" fixed-width/>
            </b-link>
          </template>

          <template v-slot:cell(itemCode)="row">
            <b-link :to="{ name: 'wyrob', params: { wyrobId: row.item.inventoryitemid}}" :title="$t('button.view.title')" class="text-dark">
              {{row.item.itemCode}}
            </b-link>
          </template>
          <template v-slot:cell(itemDesc)="row">
            <b-link :to="{ name: 'wyrob', params: { wyrobId: row.item.inventoryitemid}}" :title="$t('button.view.title')" class="text-dark">
              {{row.item.itemDesc}}
            </b-link>
          </template>

          <template v-slot:head()="data">
            {{data.label.replaceAll("_"," ")}}
          </template>
    </b-table>
    <b-row>
        <b-col md="8" class="my-1">
            <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
        </b-col>
        <b-col md="4" class="my-1">
            <TableSize v-model="perPage" :totalRows="totalRows"/>
        </b-col>
    </b-row>

  </b-container>
</template>

<script>
import CatalogGroupSelect from '../components/CatalogGroupSelect'
import SearchFilter from '../components/SearchFilter'
import TableSize from '@/components/TableSize.vue'
import tableMixin from '@/mixins/table'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import { iconCallback } from '@/plugins/toasted-interceptor'

export default {
  components: { CatalogGroupSelect, SearchFilter, TableSize, ValidationObserver },
  mixins: [tableMixin],
  data () {
    return {
      canLoadData: false,
      selectToggle: true,
      selectedIds: [],
      loadingCatalogSubGroups: false,
      loadingNierot: false,
      catalogSubGroups: [],
      nierotList: [],
      showFilter: true,
      showFilterElem: true,
      sortBy: 'itemCode',
      filter: {
        itemCatalogGroupId: { fieldType: 'select', key: 'itemcataloggroupid', nonresettable: true, value: this.$store.getters['settings/defaultCatalog'] },
        itemCode: { fieldType: 'input', value: {} },
        itemDesc: { fieldType: 'input', value: {} },
        nierotKategoria: { fieldType: 'select', key: 'id', nonresettable: false, value: {} },
        items: []
      },
      filterStr: null,
      allowedFilterValues: {},
      allowedSubGroups: {},
      isBusy: false
    }
  },
  created () {
    if (this.$route.query.itemCode) {
      this.resetFilter()
      this.filter.items.splice(0, this.filter.items.length)
      this.addSearchItem(this.$route.query.itemCode)
      this.filterStr = JSON.stringify(this.filter)
      this.canLoadData = true
    }
    if (this.$store.getters['table/getTable'](this.$route.name)) {
      this.canLoadData = true
    }
    if (this.filter.itemCatalogGroupId.value && this.filter.itemCatalogGroupId.value.itemcataloggroupid && !this.canLoadData) {
      this.changeCatalogGroup(this.filter.itemCatalogGroupId.value, this.filter.itemCatalogGroupId.value.itemcataloggroupid)
    }
  },
  computed: {
    ...mapGetters({
      defaultCatalog: 'settings/defaultCatalog'
    }),
    fields () {
      var vm = this
      var fields = [
        { key: 'selected', label: '', stickyColumn: true },
        { key: 'itemCode', label: this.getColumnLabel('itemCode'), sortable: true, tdClass: 'tdMinItemWidth', stickyColumn: true },
        { key: 'itemDesc', label: this.getColumnLabel('itemDesc'), sortable: true, tdClass: 'tdMinDescriptionWidth' },
        { key: 'kategoria', label: this.getColumnLabel('kategoria'), sortable: true },
        { key: 'inKar', label: this.getColumnLabel('inkar'), sortable: false }
      ]

      if (this.$auth.hasAnyRole(['ROLE_PRICES'])) {
        fields.push({ key: 'price',
          label: this.getColumnLabel('price'),
          sortable: true,
          class: 'text-right',
          formatter: value => { return value || value === 0 ? this.$n(value, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '' }
        })
      }

      if (this.$auth.hasAnyRole(['ROLE_SEE_PCL'])) {
        fields.push({ key: 'pclQty',
          label: this.getColumnLabel('magState'),
          sortable: true,
          class: 'text-right',
          formatter: value => { return value || value === 0 ? this.$n(value, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '' }
        })
      }

      if (this.$auth.hasAnyRole(['ROLE_SEE_NIEROT'])) {
        fields.push({ key: 'nierotKategoria',
          label: this.getColumnLabel('nierot'),
          sortable: true,
          class: 'text-left'
        })
      }

      for (var field in vm.filter) {
        if (vm.filter[field].custom) {
          fields.push({
            key: field,
            label: vm.filter[field].label,
            sortable: false
          })
        }
      }

      return fields
    }
  },
  methods: {
    getAllowedValues (name) {
      return this.allowedFilterValues[name]
    },
    localApplyFilter () {
      this.canLoadData = true
      return this.applyFilter()
    },
    async localDataProvider (ctx) {
      if (!this.canLoadData) {
        return []
      } else {
        let response = await this.dataFilterProvider(ctx)
        this.allowedFilterValues = Object.assign({}, response.filters)
        this.allowedSubGroups = response.subGroups
        return response.content || []
      }
    },
    saveDefaultCatalog (itemCatalogGroup) {
      this.$store.commit('settings/defaultCatalog', itemCatalogGroup)
    },
    onRowSelected (items) {
      this.selectedIds = items.map(x => x.inventoryitemid)
    },
    resetFilterAndCatalog () {
      this.changeCatalogGroup(this.filter.itemCatalogGroupId.value, this.filter.itemCatalogGroupId.value.itemcataloggroupid)
    },
    changeCatalogGroup (selectedOption, id) {
      var vm = this

      this.canLoadData = false
      this.resetFilter()

      this.catalogSubGroups = []

      this.filter.items = []
      this.allowedFilterValues = {}

      for (var field in vm.filter) {
        if (vm.filter[field].custom) {
          vm.$delete(vm.filter, field)
        }
      }

      if (selectedOption && selectedOption.itemcataloggroupid) {
        vm.$http.get('data/catalogElements', {
          params: {
            catalogGroupId: selectedOption.itemcataloggroupid
          }
        })
          .then((response) => {
            response.data.forEach(element => {
              vm.$set(vm.filter, element.elementname, { fieldType: 'select', value: null, key: 'elementvalue', custom: true, label: element.elementname })
            })
            this.elements = response.data
          })
          .catch((error) => {
            console.log(error.response.data)
          })
      }
    },
    addSearchItem (searchItemCode) {
      this.filter.items.push({
        editing: searchItemCode == null,
        itemCatalogGroupId: { fieldType: 'select', value: null, key: 'itemcataloggroupid' },
        itemCode: { fieldType: 'input', value: { equals: searchItemCode } },
        quantity: { fieldType: 'input', value: {} },
        dataNotEquals: { fieldType: 'input', value: { equals: false }, default: { equals: null } }
      })
    },
    delSearchItem (idx) {
      this.filter.items.splice(idx, 1)
    },
    loadCatalogSubGroups (catalogGroupId) {
      if (this.allowedSubGroups.length > 0) {
        this.catalogSubGroups = this.allowedSubGroups.sort((a, b) => (a.description > b.description) ? 1 : -1)
        return
      }
      if (this.catalogSubGroups && this.catalogSubGroups.length > 0) return

      this.loadingCatalogSubGroups = true
      this.$http.get('data/catalogSubGroups', { params: { 'catalogGroupId': catalogGroupId } })
        .then((response) => {
          this.catalogSubGroups = response.data.sort((a, b) => (a.description > b.description) ? 1 : -1)
          this.loadingCatalogSubGroups = false
        })
        .catch((error) => {
          console.log(error.response.data)
        }
        )
    },
    loadNierot () {
      if (this.nierotList.length > 0) {
        return
      }

      this.loadingNierot = true
      this.$http.get('data/catalogNierot', {
        params: {
          catalogGroupId: this.filter.itemCatalogGroupId.value.itemcataloggroupid
        } })
        .then((response) => {
          this.nierotList = response.data.sort((a, b) => (a > b) ? 1 : -1)
          this.loadingNierot = false
        })
        .catch((error) => {
          console.log(error.response.data)
        })
    },
    getItemElements (selectedOption, id, idx) {
      var vm = this

      for (var field in vm.filter.items[idx]) {
        if (vm.filter.items[idx][field].custom) {
          vm.$delete(vm.filter.items[idx], field)
        }
      }

      if (selectedOption && selectedOption.itemcataloggroupid) {
        vm.$http.get('data/catalogElements', {
          params: {
            catalogGroupId: selectedOption.itemcataloggroupid
          }
        })
          .then((response) => {
            response.data.forEach(element => {
              vm.$set(vm.filter.items[idx], element.elementname, { fieldType: 'select', value: null, key: 'elementvalue', custom: true, label: element.elementname })
            })
            this.elements = response.data
          })
          .catch((error) => {
            console.log(error.response.data)
          })
      }
    },
    toogleElemFilter (editable) {
      this.filter.items.forEach(item => { item.editing = editable })
    },
    getFilterParam (filter) {
      var filterParam = {}
      for (var field in filter) {
        if (filter[field].constructor === Array) {
          filterParam[field] = []
          filter[field].forEach(item => {
            filterParam[field].push(this.getFilterParam(item))
          })
        } else {
          switch (filter[field].fieldType) {
            case 'select' :
              if (filter[field].value) {
                var key = filter[field].key ? filter[field].key : 'id'
                if (filter[field].value.constructor === Array) {
                  if (filter[field].value.length > 0) {
                    filterParam[field] = {}
                    filterParam[field]['in'] = filter[field].value.map(x => x[key])
                  }
                } else if (filter[field].value[key]) {
                  filterParam[field] = {}
                  filterParam[field]['equals'] = filter[field].value[key]
                }
              }
              break
            default:
              var values = filter[field].value
              for (var type in values) {
                if (values[type] || values[type] === 0) {
                  if (!filterParam[field]) filterParam[field] = {}
                  filterParam[field][type] = values[type]
                }
              }
          }
        }
      }

      return filterParam
    },
    onPage (data) {
      this.toogleElemFilter(false)
    },
    getPromise (ctx) {
      var param = {}

      param.page = ctx.currentPage - 1
      param.size = ctx.perPage
      param.sort = ctx.sortBy ? ctx.sortBy + ',' + (ctx.sortDesc ? 'desc' : 'asc') : ''

      return this.$http.request({
        url: ctx.apiUrl ? ctx.apiUrl : this.apiUrl,
        method: 'post',
        params: param,
        data: this.getFilterParam(JSON.parse(ctx.filter))
      })
    },
    onExport (url) {
      let ids = this.selectedIds
      this.isBusy = true
      this.$http.post(url, ids, { responseType: 'blob' }).then(response => {
        this.isBusy = false
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
        this.hrefExport = url

        this.$toasted.info(this.$t('toast.exported'), {
          iconPack: 'callback',
          duration: 30000,
          icon: (el) => iconCallback(el, { prefix: 'fas', iconName: 'exclamation-triangle' }),
          action: [{
            text: this.$t('button.show.label'),
            href: url,
            target: '_blanck'
          },
          {
            icon: 'x',
            text: '',
            onClick: (e, toastObject) => {
              toastObject.goAway(0)
            }
          }]
        })
      }).catch(ex => {
        console.log('err:')
        console.log(ex)
        this.isBusy = false
      })
    },
    onExportGoogle (url) {
      let ids = this.selectedIds
      this.isBusy = true
      this.$http.post(url, ids).then(response => {
        this.isBusy = false
        this.$toasted.info(this.$t('toast.exported'), {
          iconPack: 'callback',
          duration: 30000,
          icon: (el) => iconCallback(el, { prefix: 'fas', iconName: 'exclamation-triangle' }),
          action: [{
            text: this.$t('button.show.label'),
            href: response.data,
            target: '_blanck'
          },
          {
            icon: 'x',
            text: '',
            onClick: (e, toastObject) => {
              toastObject.goAway(0)
            }
          }]
        })
      }).catch(ex => {
        console.log('err:')
        console.log(ex)
        this.isBusy = false
      })
    }
  }
}
</script>

<style>

</style>
